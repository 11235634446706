<template>
  <a-spin :spinning="loading" size="large">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      class="lost-refund-edit"
    >
      <a-row :gutter="24">
        <InfoTitle title="学生信息" />
        <a-col :span="8">
          <a-form-model-item label="学 号" prop="studentCode">
            <a-select v-model="form.studentCode" show-search @search="studentSearch" @change="studentChange">
              <a-select-option v-for="item in studentOptions" :key="item.code" :value="item.code">
                {{ `${item.fullName} / ${item.code}` }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="科 目" prop="subject">
            <a-select v-model="form.subject" @change="subjectChange">
              <a-select-option v-for="item in subjectArr" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="当前授课老师" prop="teachers">
            <a-select
              v-model="form.teachers"
              show-search
              @search="searchTeacher"
              :filter-option="false"
              mode="multiple"
            >
              <a-select-option v-for="item in teacherOptions" :key="item.uuid" :value="item.uuid">
                {{ item.fullName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="学生ID" prop="studentId">
            <a-input v-model="form.studentId" style="width: 100%" disabled />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="当前科目类型" prop="subjectTypeId">
            <a-select v-model="form.subjectTypeId" @change="subjectTypeChange">
              <a-select-option
                v-for="item in getSubjectType(scheduleOptions.subjectType)"
                :key="item.id"
                :value="item.id"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="流失前班主任" prop="classAdmin">
            <a-select
              v-model="form.classAdmin"
              show-search
              @search="loadClassAdminOptions"
              :filter-option="filterOption"
              option-filter-prop="children"
            >
              <a-select-option v-for="item in classAdminList" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="当前班型" prop="classType">
            <a-select v-model="form.classType">
              <a-select-option v-for="item in scheduleOptions.classType" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="当前级别I" prop="currentLevel">
            <a-select v-model="form.currentLevel">
              <a-select-option v-for="item in level1Options" :key="item.uuid" :value="item.uuid">
                {{ item.nodeName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="流失前学习规划师" prop="consultant">
            <a-select show-search :filter-option="false" v-model="form.consultant" @search="searchConsultant">
              <a-select-option v-for="item in consultantOptions" :key="item.uuid" :value="item.uuid">
                {{ item.fullName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <InfoTitle title="流失信息" />
        <a-col :span="8">
          <a-form-model-item label="流失类型" prop="lossRefundType">
            <a-select v-model="form.lossRefundType" :disabled="!!studentId" @change="lossTypeChange">
              <a-select-option
                v-for="item in scheduleOptions.lossRefundType"
                :key="item.value"
                :disabled="
                  !(
                    userInfo.roleArr.includes('TEACHING_STAFF') ||
                    userInfo.roleArr.includes('TEACHING_STAFF_ADMIN') ||
                    userInfo.roleArr.includes('HEAD_TEACHER_SUPER')
                  ) && item.value === 'REFUND'
                "
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="流失主要原因" prop="reasonOne">
            <a-select v-model="form.reasonOne" @change="reasonOneChange" :options="mainReasonOptions"></a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="流失主因II" prop="reasonTwo">
            <a-select v-model="form.reasonTwo" @change="reasonTwoChange" :options="mainReasonIIOptions"></a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="流失主因III" prop="reasonThree">
            <a-select v-model="form.reasonThree" :options="mainReasonIIIOptions"></a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="其他流失原因" prop="reasonOther">
            <a-select v-model="form.reasonOther" mode="multiple" :options="otherLossReasonOptions"></a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="流失去向" prop="direction">
            <a-select v-model="form.direction">
              <a-select-option v-for="item of scheduleOptions.lossRefundReason.directions" :key="item" :value="item">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="form.lossRefundType === 'REFUND'">
          <a-form-model-item label="退费原因分类" prop="category">
            <a-select v-model="form.category" mode="multiple">
              <a-select-option v-for="item in scheduleOptions.lossRefundReason.refundReasons" :key="item" :value="item">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="form.lossRefundType === 'REFUND'">
          <a-form-model-item label="退费状态" prop="refundStatus">
            <a-select v-model="form.refundStatus">
              <a-select-option value="全部退">全部退</a-select-option>
              <a-select-option value="部分退">部分退</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col :span="8" v-if="form.lossRefundType === 'NOT_RENEW'">
          <a-form-model-item label="不续费原因分类" prop="category">
            <a-select v-model="form.category" mode="multiple">
              <a-select-option
                v-for="item in scheduleOptions.lossRefundReason.notRenewReasons"
                :key="item"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="form.lossRefundType === 'REFUND' || form.lossRefundType === 'NOT_RENEW'">
          <a-form-model-item label="已扣课时" prop="consumedCourseUnit">
            <a-input-number v-model="form.consumedCourseUnit" style="width: 100%" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="form.lossRefundType === 'NOT_RENEW'">
          <a-form-model-item label="不续费决定日期" prop="decidedTime">
            <a-date-picker v-model="form.decidedTime" style="width: 100%" @change="decidedTimeChange" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="form.lossRefundType === 'REFUND'">
          <a-form-model-item label="退费决定日期" prop="decidedTime">
            <a-date-picker v-model="form.decidedTime" style="width: 100%" @change="decidedTimeChange" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="form.lossRefundType === 'REFUND'">
          <a-form-model-item style="position: relative" label="试学期退费" prop="refundTrySemester">
            <span>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>本次退费是否属于试学期退费情况，请选择</span>
                </template>
                <i class="explain"></i>
              </a-tooltip>
            </span>
            <a-select v-model="form.refundTrySemester">
              <a-select-option value="是">是</a-select-option>
              <a-select-option value="否">否</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="form.lossRefundType === 'REFUND'">
          <a-form-model-item label="入学标准" style="position: relative" prop="entranceStandard">
            <span>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>退费学生在入学时是否符合该学科入学标准，请选择</span>
                </template>
                <i class="explain" style="left: -107px"></i>
              </a-tooltip>
            </span>
            <a-select v-model="form.entranceStandard">
              <a-select-option value="符合">符合</a-select-option>
              <a-select-option value="不符合">不符合</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <InfoTitle title="挽单信息" />
        <a-col :span="8">
          <a-form-model-item label="班主任沟通记录" prop="classAdminContact">
            <a-textarea v-model="form.classAdminContact" auto-size :maxlength="1000" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="学习规划师沟通记录" prop="consultantContact">
            <a-textarea v-model="form.consultantContact" auto-size :maxlength="1000" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="教学沟通记录" prop="teachContact">
            <a-textarea v-model="form.teachContact" auto-size :maxlength="1000" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="其他原因备注" prop="otherRemark">
            <a-textarea v-model="form.otherRemark" auto-size />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="挽单沟通描述" prop="detain_description">
            <a-textarea v-model="form.detainDescription" auto-size :maxlength="1000" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="挽单沟通记录" prop="timezone">
            <a-upload
              name="file"
              :file-list="fileList"
              list-type="picture-card"
              @change="handleChange"
              @preview="handlePreview"
            >
              <div v-if="fileList.length < 3">
                <a-icon :type="uploading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelPic">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row :gutter="24" v-if="form.lossRefundType === 'REFUND'">
        <h3>订单信息:</h3>
        <a-col :span="8">
          <a-form-model-item label="关联订单号" prop="orders">
            <a-textarea v-model="form.orders" auto-size placeholder="请输入订单号，以逗号间隔" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="退费金额" prop="refundAmount">
            <a-input-number style="width: 100%" v-model="form.refundAmount" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="退费币种" prop="refundCurrency">
            <a-select v-model="form.refundCurrency">
              <a-select-option :value="item" v-for="(item, index) in refundCurrencyArr" :key="index">{{
                item
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-form-model-item :wrapper-col="{ span: 14, offset: 10 }">
        <a-button style="margin-left: 30px" @click="resetForm"> 取消 </a-button>
        <a-button type="primary" style="margin-left: 30px" @click="onSubmit"> 提交 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-spin>
</template>
<script>
import storage from 'store';
import moment from 'moment';
import { getReq, postReq, putReq, loadClassAdminOptions } from '@/api/schedule';
import { getDdlLabel } from '@/utils/util';
import { imgpostUrl } from '@/api/register';
import { getUploadOssToken, getAboutInfo, getCourseScheduleList, queryScreenOption } from '@/api/headTeacher';
import BaseForm from '@/components/BaseForm/index.vue';
import InfoTitle from '@/components/WkInfoTitle/InfoTitle';

export default {
  extends: BaseForm,
  components: { InfoTitle },

  data() {
    return {
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      refundCurrencyArr: ['CNY', 'NZD', 'USD', 'AUD', 'HKD', 'EUR', 'CHF', 'GBP', 'JPY', 'SGD', 'CAD'],
      getDdlLabel,
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      previewVisible: false,
      previewImage: '',
      uploading: false,
      refundId: this.$route.query.id,
      loading: false,
      roles: [],
      userInfo: {},
      form: {
        studentId: null,
        studentCode: null,
        subject: undefined,
        classType: null,
        subjectTypeId: undefined,
        reasonOne: null,
        reasonTwo: null,
        reasonThree: null,
        category: [],
        reasonOther: [],
        lossRefundType: null,
        refundStatus: null,
        consumedCourseUnit: null,
        currentLevel: null,
        teachers: [],
        consultant: null,
        classAdmin: null,
        classAdminContact: null,
        consultantContact: null,
        teachContact: null,
        otherRemark: null,
        decidedTime: null,
        orders: null,
        refundAmount: null,
        refundCurrency: null,
        detainDescription: null,
        direction: null,
        refundTrySemester: null,
        entranceStandard: null,
        detain: {
          classMasterRecord: [],
        },
      },
      rules: {
        studentId: [{ required: true, message: '请输入学生ID', trigger: 'change' }],
        studentCode: [{ required: true, message: '请选择学号', trigger: 'change' }],
        subject: [{ required: true, message: '请选择科目', trigger: 'change' }],
        classType: [{ required: true, message: '请选择班型', trigger: 'change' }],
        subjectTypeId: [{ required: true, message: '请选择科目类型', trigger: 'change' }],
        reasonOne: [{ required: true, message: '请选择流失主要原因', trigger: 'change' }],
        reasonTwo: [{ required: true, message: '请选择流失主因II', trigger: 'change' }],
        reasonThree: [{ required: true, message: '请选择流失主因III', trigger: 'change' }],
        reasonOther: [{ required: true, message: '请选择其他流失原因', trigger: 'change' }],
        direction: [{ required: true, message: '请选择流失去向', trigger: 'change' }],

        lossRefundType: [{ required: true, message: '请选择流失类型', trigger: 'change' }],
        currentLevel: [{ required: true, message: '请选择当前级别', trigger: 'change' }],
        teachers: [{ required: true, message: '请选择授课老师', trigger: 'change' }],
        consultant: [{ required: true, message: '请选择流失前学习规划师', trigger: 'change' }],
        classAdmin: [{ required: true, message: '请选择流失前班主任', trigger: 'change' }],
        classAdminContact: [{ required: true, message: '请输入班主任沟通记录', trigger: 'change' }],
        decidedTime: [{ required: true, message: '请选择决定日期', trigger: 'change' }],
        refundTrySemester: [{ required: true, message: '请选择试用期退费', trigger: 'change' }],
        entranceStandard: [{ required: true, message: '请选择入学标准', trigger: 'change' }],
        refundStatus: [{ required: true, message: '请选择退费状态', trigger: 'change' }],

        // orders: [{ required: true, message: '请输入订单号', trigger: 'change' }],
        consumedCourseUnit: [{ required: true, message: '请输入已扣课时', trigger: 'change' }],
        // refundStatus: [{ required: true, message: '请选择退款状态', trigger: 'change' }],
      },
      apiUrl: '/api/admin/scheduling/loss_refund/',
      fileList: [],
      formData: null,

      studentOptions: [],
      orderOptions: [],
      teacherOptions: [],
      level1Options: [],
      classAdminList: [],
      consultantOptions: [],

      // 远程下拉枚举
      remoteOptionDataSource: {},
    };
  },
  computed: {
    // 主要流失原因 options
    mainReasonOptions() {
      console.log('this.remoteOptionDataSource?.lossRefundReason', this.remoteOptionDataSource?.lossRefundReason);
      if (!this.remoteOptionDataSource?.lossRefundReason) return [];
      return Object.keys(this.remoteOptionDataSource?.lossRefundReason).map((key) => ({
        value: key,
        label: key,
      }));
    },
    // 主要流失原因II options
    mainReasonIIOptions() {
      if (!this.form.reasonOne) return [];

      return this.remoteOptionDataSource?.lossRefundReason?.[this.form.reasonOne]?.map((item) => {
        return {
          value: Object.keys(item)[0],
          label: Object.keys(item)[0],
        };
      });
    },
    // 主要流失原因III options
    mainReasonIIIOptions() {
      if (!this.form.reasonTwo || !this.form.reasonOne) return [];

      const two =
        this.remoteOptionDataSource?.lossRefundReason?.[this.form.reasonOne]?.find(
          (item) => Object.keys(item)[0] === this.form.reasonTwo,
        ) ?? {};
      return Object.entries(two)?.[0]?.[1]?.map((key) => ({
        value: key,
        label: key,
      }));
    },
    // 其它流失原因
    otherLossReasonOptions() {
      return this.remoteOptionDataSource?.lossRefundReasonList?.map((key) => ({
        value: key,
        label: key,
      }));
    },
  },
  async created() {
    this.roles = storage.get('roles');
    this.userInfo = storage.get('userInfo');
    // 流失主因枚举
    queryScreenOption().then((res) => {
      this.remoteOptionDataSource = res?.data?.content || {};
    });

    if (this.refundId) {
      this.loading = true;
      getReq(`${this.apiUrl}${this.refundId}`, {})
        .then((res) => {
          const { content } = res.data;
          // 回显流失前班主任
          this.loadClassAdminOptions(content.classAdminData.fullName);
          for (const key in content) {
            this.form[key] = content[key];
          }
          this.postProcess(content);

          // 填充不续费日期
          // this.applyDate({
          //   timezone:content?.studentRespDTO?.timezoneStd,
          //   studentId:this.refundId,
          //   subject: content?.subject
          // })
        })
        .finally(() => {
          this.loading = false;
        });
    }

    const { code, subject } = this.$route.query;
    if (code && subject) {
      this.loading = true;
      await this.studentSearch(code);
      this.form.studentCode = code;
      this.form.subject = subject;
      this.studentChange(code);
      this.loading = false;
    }
  },
  watch: {
    'form.subjectTypeId': function (e) {
      if (e) {
        this.level1Options = [];
        // 获取当前级别I下拉数据
        postReq('/api/admin/scheduling/config/search', { parentId: e, orderBy: 'sort asc' }).then((res) => {
          this.level1Options = res.data;
        });
      }
    },
  },
  methods: {
    // 填充不续费日期
    applyDate({ timezone, studentId, subject } = {}) {
      getCourseScheduleList({
        subject,
        orderBy: 'courseSchedule.endDateTime desc',
        scheduleStatuses: ['COMPLETED'],
        timezone,
        studentId,
        pageSize: 1,
        pageNum: 1,
        courseType: 'STANDARD',
      }).then((res) => {
        const time = res.data?.content?.[0]?.courseScheduleRespDTO?.endDateTime;
        if (time) {
          this.form.decidedTime = moment(time);
        }
      });
    },
    loadClassAdminOptions(name) {
      const params = {
        name,
      };
      loadClassAdminOptions(params).then((res) => {
        this.classAdminList = res.data.content;
      });
    },

    // 初始化要编辑的数据
    postProcess(data) {
      this.teacherOptions = data.teachersData;
      this.consultantOptions = [].concat(data.consultantData);
      this.studentOptions = [].concat(data.studentRespDTO);
      // this.fileList = [].concat(data.detain.classMasterRecord);

      const tempArr = [];
      for (let i = 0; i < data.detain.classMasterRecord.length; i++) {
        const element = data.detain.classMasterRecord[i];
        tempArr.push({
          url: element.contentUrl,
          uid: element.fileStorageId,
          name: `${element.fileStorageId}.png`,
          status: 'done',
          response: {
            contentUrl: element.contentUrl,
            fileStorageId: element.fileStorageId,
          },
        });
      }
      this.fileList = [].concat(tempArr);
    },
    handleChange(info) {
      if (info.file.status === 'removed') {
        this.fileList = info.fileList;
      } else {
        this.imgInfo = info;
        this.uploading = true;
        getUploadOssToken().then((res) => {
          this.uploadToOss(info, res.data.content);
        });
      }
    },
    handleCancelPic() {
      this.previewVisible = false;
    },
    uploadToOss(info, fileData) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info.file.originFileObj);
      imgpostUrl(`https://${fileData.endpoint}`, formData)
        .then((res) => {
          this.fileList.push({
            uid: res.data.fileId,
            name: 'image.png',
            status: 'done',
            url: res.data.url,
          });
        })
        .finally(() => {
          this.uploading = false;
        });
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    async studentSearch(e) {
      await postReq('/api/admin/student/v2/search/1', {
        code: e,
      }).then((res) => {
        this.studentOptions = res.data.content;
      });
    },
    applyInfo(params) {
      const { studentId, subject } = params;
      if (!studentId || !subject) return;
      const student = this.studentOptions.find((item) => {
        return item.uuid === studentId;
      });

      // options-前班主任
      this.loadClassAdminOptions(student?.classAdmin?.[subject]?.fullName);
      this.form.classAdmin = student?.classAdmin?.[subject]?.accountId;
      // options-前规划师
      this.searchConsultant(student?.data?.courseConsultant?.[subject]?.name);
      this.form.consultant = student?.data?.courseConsultant?.[subject]?.Id;

      getAboutInfo({
        chinaTimeZone: false,
        pageSize: 20,
        studentId,
        subject,
        scheduleStatuses: ['COMPLETED'],
        statuses: [],
        startDateFrom: '',
        startDateTo: '',
        studentTimeZone: student?.timezoneStd,
        orderBy: 'courseSchedule.endDateTime desc',
        courseType: 'STANDARD',
      }).then((res) => {
        const data = res.data.content;

        if (!data) return;
        // 当前授课老师
        this.form.teachers = [data?.courseScheduleRespDTO?.teacher?.uuid].filter(Boolean);
        if (this.form.teachers.length) {
          this.teacherOptions = [
            {
              uuid: data?.courseScheduleRespDTO?.teacher?.uuid,
              fullName: data?.courseScheduleRespDTO?.teacher?.fullName,
            },
          ];
        }
        this.form.subjectTypeId = this.scheduleOptions.subjectType?.find(
          (item) => item.label === data?.courseScheduleRespDTO?.courseSectionRespDTO?.data?.subjectType_display,
        )?.id;
        this.form.classType = data?.courseScheduleRespDTO?.classType;

        // 当前级别
        postReq('/api/admin/scheduling/config/search', { parentId: this.form.subjectTypeId, orderBy: 'sort asc' }).then(
          (l1res) => {
            this.level1Options = l1res.data;
            this.form.currentLevel = this.level1Options?.find(
              (item) => item?.nodeContent?.value === data?.courseScheduleRespDTO?.data?.level1,
            )?.uuid;
          },
        );
      });
    },
    studentChange(e) {
      const currentStu = this.studentOptions.filter((item) => {
        return item.code === e;
      })[0];
      if (!currentStu) return;
      this.form.studentId = currentStu.uuid;
      // 获取学生关联订单
      postReq('/api/ecommerce/order/search/1', { studentCode: currentStu.code }).then((res) => {
        this.orderOptions = res.data.content;
      });

      // 自动填充相关信息
      this.applyInfo({ studentId: this.form.studentId, subject: this.form.subject });
      // 填充不续费日期
      this.applyDate({
        timezone: currentStu?.studentRespDTO?.timezoneStd,
        studentId: currentStu.uuid,
        subject: currentStu?.subject,
      });
    },
    searchTeacher(e) {
      postReq('/api/admin/teacher/search/1', { fullName: e }).then((res) => {
        this.teacherOptions = res.data.content;
      });
    },
    // 科目
    subjectChange(e) {
      // 清空二三级
      this.form.subjectTypeId = null;
      this.form.currentLevel = null;
      this.level1Options = [];
      this.applyInfo({
        studentId: this.form.studentId,
        subject: e,
      });
      const student = this.studentOptions.find((item) => {
        return item.uuid === this.form.studentId;
      });
      // 填充不续费日期
      this.applyDate({
        timezone: student?.studentRespDTO?.timezoneStd,
        studentId: student?.uuid,
        subject: e,
      });
    },
    getSubjectType(subjectTypes) {
      return subjectTypes.filter((item) => {
        return item.subject === this.form.subject;
      });
    },
    subjectTypeChange(e) {
      // 清空当前级别I数据
      this.form.currentLevel = null;
    },
    searchConsultant(e) {
      postReq('/api/admin/user/search_user/1', {
        roles: ['COURSE_CONSULTANT', 'CRM_CC', 'CRM_CC_LEADER', 'CLASS_ADMIN', 'HEAD_TEACHER_SUPER'],
        name: e,
      }).then((res) => {
        this.consultantOptions = res.data.content.content;
      });
    },
    reasonOneChange() {
      // 清空reason2&3
      this.form.reasonTwo = null;
      this.form.reasonThree = null;
    },
    reasonTwoChange() {
      // 清空reason3
      this.form.reasonThree = null;
    },
    decidedTimeChange(date, dateStr) {
      this.form.decidedTime = dateStr;
    },
    lossTypeChange(e) {
      this.form.category = [];
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const imgList = [];
          for (const item of this.fileList) {
            imgList.push({
              contentUrl: item.url,
              fileStorageId: item.uid,
            });
          }
          this.form.decidedTime = moment(this.form.decidedTime).format('YYYY-MM-DD HH:mm:ss');
          if (this.refundId) {
            putReq(`${this.apiUrl}${this.refundId}`, { ...this.form, detain: { classMasterRecord: imgList } })
              .then((res) => {
                this.$message.success('提交成功!');
                history.go(-1);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            postReq(this.apiUrl, { ...this.form, detain: { classMasterRecord: imgList } })
              .then((res) => {
                this.$message.success('提交成功!');
                history.go(-1);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      history.go(-1);
    },
  },
};
</script>
<style scoped lang="less">
.lost-refund-edit {
  width: 100%;
  padding: 18px 20px 0 32px;
  background-color: #fff;
  min-height: calc(100vh - 80px);
}

.explain {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('~@/assets/explain1.png') no-repeat center;
  background-size: 100%;
  margin-left: 4px;
  cursor: pointer;
  position: absolute;
  left: -123px;
  top: 1px;
  z-index: 1000;
}
</style>
